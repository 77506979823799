import "./src/css/global.scss";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, Functions } from 'firebase/functions';
import { FirebaseUtils } from './src/utils/firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBWwKlemI7IWBrxbKVTWTv8x4LWOeU73gA",
    authDomain: "reka-webpage.firebaseapp.com",
    projectId: "reka-webpage",
    storageBucket: "reka-webpage.appspot.com",
    messagingSenderId: "614846319601",
    appId: "1:614846319601:web:f1f3a8550010338e179250",
    measurementId: "G-9FD8YVJ39W"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app, 'europe-west1');

FirebaseUtils.i().setFunctions(functions);