import {Functions} from 'firebase/functions';

export class FirebaseUtils {
    private static instance: FirebaseUtils;
    private functions!: Functions;
    private constructor() { }

    public static i(): FirebaseUtils {
        if (!FirebaseUtils.instance) {
            FirebaseUtils.instance = new FirebaseUtils();
        }
        return FirebaseUtils.instance;
    }

    setFunctions(functions: Functions) {
        this.functions = functions;
    }

    getFunctions(): Functions {
        return this.functions;
    }
}